import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {BACKOFFICE_PATHS} from 'shared/constants.js';

import UserAccount from './UserAccount.js';
import SidebarItem from './SidebarItem.js';
import styles from './Sidebar.module.scss';
import {ReactComponent as LogoSvg} from './momo_logo.svg';

export default function Sidebar() {
  const {is_admin} = useSelector((state) => state.user.account);
  return (
    <div className={styles.sidebar}>
      <div>
        <Link to="/">
          <div className={styles.logo}>
            <LogoSvg />
          </div>
        </Link>
        <div className={styles.item_wrapper}>
          <SidebarItem name="Managers" to={BACKOFFICE_PATHS.managers} />

          <SidebarItem
            name="Rental Contracts"
            to={BACKOFFICE_PATHS.rental_contracts}
          />

          <SidebarItem name="Deposits" to={BACKOFFICE_PATHS.deposits} />

          {is_admin && (
            <SidebarItem
              name="Deposit Guarantee Applications"
              to={BACKOFFICE_PATHS.deposit_guarantee_applications}
            />
          )}

          <SidebarItem
            name="Property Owners"
            to={BACKOFFICE_PATHS.property_owners}
          />

          <SidebarItem name="Claims" to={BACKOFFICE_PATHS.claims} />

          <SidebarItem
            name="Deposit Accounts"
            to={BACKOFFICE_PATHS.deposit_accounts}
          />

          <SidebarItem
            name="Deposit Account Vibans"
            to={BACKOFFICE_PATHS.deposit_account_vibans}
          />

          {is_admin && (
            <SidebarItem
              name="Cash Deposit Import"
              to={BACKOFFICE_PATHS.cash_deposit_import}
            />
          )}

          {is_admin && (
            <>
              <SidebarItem
                name="VVRB Requests"
                to={BACKOFFICE_PATHS.vvrb_requests}
              />

              <SidebarItem
                name="VVRB Events"
                to={BACKOFFICE_PATHS.vvrb_events}
              />

              <SidebarItem
                name="EverReal Events"
                to={BACKOFFICE_PATHS.everreal_events}
              />

              <SidebarItem
                name="Covivio Events"
                to={BACKOFFICE_PATHS.covivio_events}
              />

              <SidebarItem
                name="Tenant Promotion Campaigns"
                to={BACKOFFICE_PATHS.tenant_promotion_campaigns}
              />

              <SidebarItem
                name="Backoffice Users"
                to={BACKOFFICE_PATHS.backoffice_users}
              />
            </>
          )}

          <SidebarItem
            name="Unallocated Payments"
            to={BACKOFFICE_PATHS.unallocated_payments}
          />
        </div>
      </div>

      <UserAccount />
    </div>
  );
}
